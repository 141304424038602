import header from '../helper/auth-header'
import { AXIOS } from '../helper/http-commons';
export default {
    GetActivityService(term,offset, maxResult) {
        let config    =  {}
        config        =   header.authHeader();
        config.params =   {
            term:      term,
            offset:    offset,
            maxResult: maxResult
        }
        return AXIOS.get('/activity/', config);
    },
    downloadActivityHistory(term, offset, maxResult){
        let config = {};
        config = header.authHeader();
        config.params = {
            term : term,
            offset : offset,
            maxResult : maxResult
        };
        return AXIOS.get('/activity/download/', config);
    }
}
