<template>
    <div class="col-xs-12 col-sm-12 col-md-9">
        <div class="content">
            <transition name="slide-fade">
                <div>
                    <div class="form-data">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <div class="fieldset">
                                        <label for="">Participant</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <div class="fieldset">
                                        <select v-model="Participant" class="Participant">
                                            <option value="">Select</option>
                                            <option value="T24">T24</option>
                                            <option value="Igate">Igate</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <div class="fieldset">
                                        <label for="">File</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <div class="">
                                        <div class="file-form">
                                            <!-- <input type="text" placeholder="Placeholder">
                                            <b-button v-b-tooltip.hover title="Cari">
                                                <i class="fas fa-search"></i>
                                            </b-button> -->
                                            <b-form-file class="file-drop" v-model="fileUpload" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-form-file>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <div class="fieldset">
                                        <label for="">Reconciliation Date</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <div class="fieldset">
                                        <input type="date" v-model="ReconciliationDate" class="ReconciliationDate">
                                    </div>
                                </div>
                            </div>
                            <div class="upload">
                                <div class="fieldset">
                                    <button class="button" :disabled="loading" style="color: white; background-color: #6c757d;" @click="Upload()">
                                        <b-spinner small v-if="loading"></b-spinner>
                                        <span class="sr-only" v-if="loading">Uploading...</span>
                                         <span v-if="!loading">Upload</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="tableview">
                        <div class="title-table">
                            <p>Preview</p>
                            <!-- <div class="progress-upload">
                                <div id="myProgress">
                                    <div id="myBar"></div>
                                </div>
                            </div> -->
                        </div>
                        <div class="tables-result">
                            <b-table :fields="fields" show-empty :current-page="currentPage" :per-page="perPage" :items="items" responsive>
                                
                            </b-table>
                            <b-pagination align="center" v-model="currentPage" :total-rows="rows" :per-page="perPage" size="sm" aria-controls="my-table"></b-pagination>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import FunctionService from '../service/FunctionService';
import ReconiliationService from '../service/ReconciliationService';
// import { VueCsvImport } from 'vue-csv-import'

export default {
    data() {
        return {
            fields:             [
            ],
            items:              [],
            Data:               [],
            Participant:        '',
            loading:            false,
            ReconciliationDate: '',
            currentPage:        1,
            totalItems:         0,
            perPage:            5,
            tableview:          false,
            fileUpload:         null,
            DataImport:         [],
            isBusy:             false
        }
    },
    methods: {
        Upload() {
            this.fields                 =       [];
            this.items                  =       [];
            if (this.Participant == '') {
                this.tableview          =       false;
                return FunctionService.notifyForm('Participant','Plesse Select Participan','info');
            } else if (this.ReconciliationDate == '') {
                return FunctionService.notifyForm('ReconciliationDate','Plesse Select date','info');
            } else if (FunctionService.CheckExtention(this.fileUpload) == true) {
                FunctionService.ReadCsv(this.fileUpload)
                .then((result) => {
                    window.console.log(result);
                    this.SendFile(result);
                    // let objData         =       [];
                    // for (let i = 0; i < result.data.length; i++) {
                    //     var     obj         =       {};
                    //     for (let j = 0; j < result.data[i].length; j++) {
                    //         this.fields.push({
                    //             key:        result.data[0][j],
                    //             label:      result.data[0][j].replace(/\./g,' '),
                    //             sortable:   true,
                    //             class:      'table-set'
                    //         });
                    //         for (let k = 0; k < result.data[i][j].length; k++) {
                    //             obj[result.data[0][j]]         =    result.data[i][j];
                    //         }
                    //     }
                    //     objData.push(obj);
                    // }
                    // this.items               =       objData;
                    // // window.console.log(objData);
                    // this.DataImport          =       result.data;
                    this.tableview           =       true;
                });
            } else {
                alert("file must csv");
            }
        },
        SendFile(result) {
            let FormSend        =       new FormData();
            FormSend.append("file", this.fileUpload);
            FormSend.append('date', this.ReconciliationDate);
            // window.console.log(FunctionService.DateLong(2005171705));
            this.loading        =       true;
            if (this.Participant == 'T24') {
                this.items      =       [];
                this.fields.push([
                    {
                        key:        "id",
                        label:      "ID",
                        sortable:   true,
                        class:      "table-set"
                    },
                    {
                        key:        "date_time",
                        label:      "DATE TIME",
                    },
                    {
                        key:        "series_id",
                        label:      "SERIES ID",
                    },
                    {
                        key:        "debit_act",
                        label:      "DEBIT ACCT NO",
                    },
                    {
                        key:        "Credit_act",
                        label:      "CREDIT ACCT NO",
                    },
                    {
                        key:        "DEBIT_VALUE_DATE",
                        label:      "DEBIT VALUE DATE",
                    },
                    {
                        key:        "CREDIT_VALUE_DATE",
                        label:      "CREDIT VALUE DATE",
                    },
                    {
                        key:        "CREDIT_CURRENCY",
                        label:      "CREDIT CURRENCY",
                    },
                    {
                        key:        "DEBIT_CURRENCY",
                        label:      "DEBIT CURRENCY",
                    },
                    {
                        key:        "CREDIT_AMOUNT",
                        label:      "CREDIT AMOUNT",
                    },
                    {
                        key:        "DEBIT_AMOUNT",
                        label:      "DEBIT AMOUNT",
                    },
                    {
                        key:        "TRANSACTION_TYPE",
                        label:      "TRANSACTION TYPE",
                    },
                    {
                        key:        "BEN_CUSTOMER",
                        label:      "BEN CUSTOMER",
                    },
                    {
                        key:        "PAYMENT_DETAILS",
                        label:      "PAYMENT DETAILS",
                    },
                    {
                        key:        "CO_CODE",
                        label:      "CO CODE",
                    },
                    {
                        key:        "INPUTTER",
                        label:      "INPUTTER",
                    },
                    {
                        key:        "TRANS_REFF",
                        label:      "TRANS REFF",
                    },
                    {
                        key:        "DEPT_CODE",
                        label:      "DEPT CODE",
                    },
                    {
                        key:        "L_MBA_CIF",
                        label:      "L MBA CIF",
                    },
                    {
                        key:        "L_OR_NUMBER",
                        label:      "L OR NUMBER",
                    },
                    {
                        key:        "L_AR_NUMBER",
                        label:      "L AR NUMBER",
                    },
                    {
                        key:        "L_REP_NAME",
                        label:      "L REP NAME",
                    },
                    {
                        key:        "L_REP_NAME",
                        label:      "L REP NAME",
                    },
                    {
                        key:        "L_REP_TIN",
                        label:      "L REP TIN",
                    },
                    {
                        key:        "L_REP_M_PHONE",
                        label:      "L REP M PHONE",
                    },
                    {
                        key:        "L_REP_ADDRESS",
                        label:      "L REP ADDRESS",
                    },
                    {
                        key:        "L_REP_BNS_TYPE",
                        label:      "L REP BNS TYPE",
                    },
                    {
                        key:        "RECORD_STATUS",
                        label:      "RECORD STATUS",
                    },
                    {
                        key:        "TERM_ID",
                        label:      "TERM ID",
                    },
                    {
                        key:        "PARTNER_ID",
                        label:      "PARTNER ID",
                    },
                ]);
                for (let i = 0; i < result.data.length; i++) {
                            this.items.push({
                                id:         result.data[i][0],
                                date_time:  FunctionService.SimpleDateFormat(result.data[i][1]),
                                series_id:  result.data[i][2],
                                debit_act:  result.data[i][3],
                                Credit_act:  result.data[i][4],
                                DEBIT_VALUE_DATE:  FunctionService.FormatDate(result.data[i][5]),
                                CREDIT_VALUE_DATE:  FunctionService.FormatDate(result.data[i][6]),
                                CREDIT_CURRENCY:  result.data[i][7],
                                DEBIT_CURRENCY:  result.data[i][8],
                                CREDIT_AMOUNT:  result.data[i][9],
                                DEBIT_AMOUNT:  result.data[i][10],
                                TRANSACTION_TYPE:  result.data[i][11],
                                BEN_CUSTOMER:  result.data[i][12],
                                PAYMENT_DETAILS:  result.data[i][13],
                                CO_CODE:  result.data[i][14],
                                INPUTTER:  result.data[i][15],
                                TRANS_REFF:  result.data[i][16],
                                DEPT_CODE:  result.data[i][17],
                                L_MBA_CIF:  result.data[i][18],
                                L_OR_NUMBER:  result.data[i][19],
                                L_AR_NUMBER:  result.data[i][20],
                                L_REP_NAME:  result.data[i][21],
                                L_REP_TIN:  result.data[i][22],
                                L_REP_M_PHONE:  result.data[i][23],
                                L_REP_ADDRESS:  result.data[i][24],
                                L_REP_BNS_TYPE:  result.data[i][25],
                                RECORD_STATUS:  result.data[i][26],
                                TERM_ID:  result.data[i][27],
                                PARTNER_ID:  result.data[i][28],
                            }); 
                }
                ReconiliationService.UploadT24(FormSend)
                .then((result) => {
                    // window.console.log(result);
                    if (result.data.code == "00") {
                        this.$notify({
                            group:   "notif",
                            type:    "success",
                            title:    "T24 Uploaded",
                            text:     result.data.message,
                            duration: 5000
                        });
                        this.loading         =      false;
                    } else if (result.data.code == "101") {
                        this.$notify({
                            group:   "notif",
                            type:    "error",
                            title:    "Message ",
                            text:     result.data.message,
                            duration: 5000
                        });
                        this.loading         =      false;
                    } else {
                        this.$notify({
                            group:   "notif",
                            type:    "error",
                            title:    "Upload T24 Failed ",
                            text:     result.data.message,
                            duration: 5000
                        });
                        this.loading         =      false;
                    }
                })
                .catch(() => {
                        this.$notify({
                            group:   "notif",
                            type:    "error",
                            title:    "Upload T24 Failed ",
                            text:     "Server Error",
                            duration: 5000
                        });
                        this.loading         =      false;
                });
            } else if (this.Participant == 'Igate') {
                let objData         =       [];
                this.fields         =       [];
                for (let i = 0; i < result.data.length; i++) {
                    var     obj         =       {};
                    for (let j = 0; j < result.data[i].length; j++) {
                        this.fields.push({
                            key:        result.data[0][j],
                            label:      result.data[0][j].replace(/\./g,' '),
                            sortable:   true,
                            class:      'table-set'
                        });
                        for (let k = 0; k < result.data[i][j].length; k++) {
                            obj[result.data[0][j]]         =    result.data[i][j];
                        }
                    }
                    objData.push(obj);
                }
                this.items               =       objData;
                ReconiliationService.UploadiGate(FormSend)
                .then((result) => {
                    if (result.data.code == "00") {
                        this.$notify({
                            group:   "notif",
                            type:    "success",
                            title:    "Igate Uploaded",
                            text:     result.data.message,
                            duration: 5000
                        });
                        this.loading         =      false;
                    } else if (result.data.code == "101") {
                        this.$notify({
                            group:   "notif",
                            type:    "error",
                            title:    "Message ",
                            text:     result.data.message,
                            duration: 5000
                        });
                        this.loading         =      false;
                    } else {
                        this.$notify({
                            group:   "notif",
                            type:    "error",
                            title:    "Upload Igate Failed ",
                            text:     result.data.message,
                            duration: 5000
                        });
                        this.loading         =      false;
                    }
                    // window.console.log(result);
                    // this.loading        =       false;
                })
                .catch(() => {
                    this.$notify({
                        group:   "notif",
                        type:    "error",
                        title:    "Upload Igate Failed ",
                        text:     "Server Error ",
                        duration: 5000
                    });
                    this.loading         =      false;
                });
            }
        }
    },
    computed: {
        rows() {
            return this.items.length;
        }
    },
    mounted: function() {
        this.Data        =       [];
        this.Data.push();
        
    }
}
</script>

<style lang="css" scoped>
    .table thead th {
        width: 100px !important;
    }
    .fieldset label {
        width: 100% !important;
    }
    
    .tables-result .table thead tr .table-set {
        text-transform: lowercase;
    }
    .title-table {
        position: relative;
        right: 7px;
    }
    .title-table p {
        margin-left: 0;
        font-size: 1.0em;
    }
    .fieldset .file-form {
        width: 100%
    }
    .fieldset .file-form button {
        color: white;
        background-color: #6c757d;
        width: 32%;
        position: absolute;
        bottom: 24px;
        height: 36px;
        width: 46px;
    }
    .file-drop {
        width: 254px;
        margin-top: 5px;
    } 
    .upload {
        position: relative;
        right: 18px;
        width: 100%;
        text-align: right;
    }

    @media only screen and (max-width: 992px) {
        .fieldset .file-form button {
            top: 27px;
        }
        .file-drop {
            width: 100%;
            margin-top: 5px;
        } 
    }
</style>