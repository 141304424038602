<template>
  <div class="col-xs-12 col-sm-12 col-md-9">
    <div class="content">
      <header>
        <h2>Transaction Info</h2>
      </header>
      <transition name="slide-fade">
        <div  v-if="!PageDetail">
          <b-row>
            <b-col sm="6" class="my-1">
              <b-form-group>
                <b-input-group >
                  <b-form-input type="search" id="filterInput" v-on:keyup.enter="fetchData()" v-model="term" placeholder="Type to Search">

                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="6" class="my-1">
              <b-form-group>
                <b-input-group>
                  <multiselect style="width: 70% !important;white-space: nowrap;" placeholder="Partner ID" deselect-label="Can't remove this value" track-by="partnerName" v-model="PartnerID" label="partnerName" :loading="loadingPartner" :options="DataPartner" :searchable="true" :allow-empty="false" @search-change="Partner">
                      <template slot="singleLabel" slot-scope="{ option }">
                        <strong>{{ option.partnerName }}</strong>
                      </template>
                  </multiselect>
                  <b-input-group-append>
                    <b-button v-b-tooltip.hover @click="fetchData()" title="Cari">
                      <i class="fas fa-search"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="download-report">
            <b-dropdown text="Download">
              <b-dropdown-item>
                <download-excel :data="itemsExport" :fields="fieldsExcel" :escapeCsv="true" worksheet="Sheet1" :name="NamaDownloadCsv" type="csv">
                  Download Csv
                </download-excel>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="col-sm-12">
            <b-table show-empty :items="items" :fields="fields" :busy="isBusy" :current-page="currentPage" :responsive="true" :per-page="0">
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(transactionDate)="data">
                {{ DateLong(data.item.transactionDate) }}
              </template>

              <template v-slot:cell(status)="data">
                <b-badge variant="success" v-if="data.item.status == 'SUCCEED'">{{ data.item.status }}</b-badge>
                <b-badge variant="danger" v-if="data.item.status == 'FAILED'">{{ data.item.status }}</b-badge>
              </template>

              <template v-slot:cell(actions)="data">
                <b-button size="sm" class="mr-1" v-b-tooltip.hover title="View details" @click="DetailView(data.item)">
                  <i class="fa fa-eye"></i>
                </b-button>
              </template>

            </b-table>
            <b-pagination align="center" v-model="currentPage" :total-rows="totalItems" :per-page="perPage" size="sm" aria-controls="my-table">
            </b-pagination>
          </div>
        </div>
      </transition>

      <transition name="slide-fade" class="">
        <div v-if="PageDetail">
            <div class="page-details">
              <div v-for="(item,property,index) in DetailSelected" :key="index">
                <div class="form-group">
                    <div class="fieldset">
                      <label>{{ property }}</label>
                      <b-form-input readonly="readonly" :value="item"></b-form-input>
                    </div>
                </div>
              </div>
              <div class="button-group">
                <button class="button-back" @click="BackDetail">Back</button>
              </div>
            </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import accLinkingService from "../service/account-linking-service";
import { isNullOrUndefined } from "util";
import FunctionService from '../service/FunctionService';
import partnerService from '../service/partner-service';
import Multiselect from "vue-multiselect";

export default {
  name: "customerlinkinginfo",
  data() {
    return {
      items: [],
      fields: [
        {
          key: "serviceType",
          label: "Service Type",
          sortable: true,
          class:    'table-set'
        },
        {
          key: "transactionDate",
          label: "Transaction Date",
          sortable: true,
          class:    'table-set'
        },
        {
          key: "fromAccountNumber",
          label: "Account Number",
          sortable: true,
          class:    'table-set'
        },
        {
          key: "toAccountNumber",
          label: "To account number",
          sortable: true,
          class:    'table-set'
        },
        {
          key: "currencyCode",
          label: "Currency Code",
          sortable: true,
          class:    'table-set'
        },
        {
          key: "transactionAmount",
          label: "Transaction Amount",
          sortable: true,
          class:    'table-set'
        },
        {
          key: "referenceNumber",
          label: "Reference Number",
          sortable: true,
          class:    'table-set'
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          class:    'table-set'
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
          class:    'table-set'
        },
        {
          key:      "partnerCode",
          label:    "Partner Code",
          sortable: true,
          class:   'table-set'
        },
        {
          key:      "partnerName",
          label:    "Partner Name",
          sortable: true,
          class:    'table-set'
        }
      ],
      NamaDownloadCsv: "",
      fieldsExcel:     {
        'Service Type':     'serviceType',
        'Transaction Date': 'transactionDate',
        'Account Number':   'fromAccountNumber',
        'To Account Number': 'toAccountNumber',
        'Currency Code': 'currencyCode',
        'Transaction Amount': 'transactionAmount',
        'Reference Number': 'referenceNumber',
        'Status': 'status',
        'Description': 'description',
        'Partner Code': 'partnerCode',
        'Partner Name': 'partnerName'
      },
      itemsExport:     [],
      currentPage:     1,
      perPage:         5,
      totalItems:      0,
      term:            "",
      isBusy:          false,
      PageDetail:      false,
      DetailSelected: [],
      DataPartner:    [],
      PartnerID:      [],
      loadingPartner: false,
      TermPartner:    "",
      maxResultPrint: 1000
    };
  },
  components: {
    Multiselect
  },
  mounted() {
    this.fetchData().catch(error => {
      window.console.error(error);
    });
    this.Print();
    this.NamaDownloadCsv    =     "Download Transaction Info "+ FunctionService.GetDateNow()+".csv";
    this.Partner();
  },
  methods: {
    async fetchData() {
      this.isBusy = true;
      window.console.log(this.PartnerID.id);
      return new Promise((resolve, reject) => {
        accLinkingService
          .getTransactionInfo(this.currentPage, this.perPage, this.term,this.PartnerID.id)
          .then(response => {
            this.totalItems = response.data.count;
            this.items = response.data.data;
            // window.console.log(response);
            this.isBusy = false;
            resolve(response);
          })
          .catch(() => {
            reject("error");
          });
      });
    },
    async Print() {
      this.isBusy = true;
      return new Promise((resolve, reject) => {
        accLinkingService
          .getTransactionInfo(this.currentPage, this.maxResultPrint, this.term,this.PartnerID.id)
          .then(response => {
            // this.totalItems = response.data.count;
            this.itemsExport = response.data.data;
            // window.console.log(response);
            this.isBusy = false;
            if (response.data.count >= this.maxResultPrint) {
              this.maxResultPrint     +=     1000;
              this.Print();
            }
            resolve(response);
          })
          .catch(() => {
            reject("error");
          });
      });
    },
    DetailView(data) {
        this.PageDetail            =          true;
        // this.DetailSelected        =          data;
        this.DetailSelected        =          {
              accountNumber:        data.accountNumber,
              accountTitle:         data.accountTitle,
              address:              data.address,
              birthPlace:           data.birthPlace,
              challengeType1:       data.challengeType1,
              challengeType2:       data.challengeType2,
              challengeValue1:      data.challengeValue1,
              challengeValue2:      data.challengeValue2,
              cif:                  data.cif,
              coCode:               data.coCode,
              customerAccessToken:  data.customerAccessToken,
              customerName:         data.customerName,
              dateOfBirth:          data.dateOfBirth,
              email:                data.email,
              gender:               data.gender,
              id:                   data.id,
              inactiveMarker:       data.inactiveMarker,
              legalDocExp:          data.legalDocExp,
              legalDocId:           data.legalDocId,
              legalDocName:         data.legalDocName,
              mmn:                  data.mmn,
              otpExpiredDate:       data.otpExpiredDate,
              otpKey:               data.otpKey,
              phone:                data.phone,
              requestExpiredDate:   data.requestExpiredDate,
              requestId:            data.requestId,
              sms:                  data.sms,
              status:               data.status
        }
        // window.console.log(data);
    },
    DateLong(date) {
        return FunctionService.DateLong(date);
    },
    BackDetail() {
      this.PageDetail              =          false;
      this.DetailSelected          =          [];
      // window.console.log(this.DetailSelected);
    },
    Partner() {
       this.loadingPartner         =          true;
       partnerService.getPartner(this.TermPartner)
                     .then((result) => {
                          if (result.status == 201) {
                              this.loadingPartner          =        false;
                              this.DataPartner             =      result.data.data;
                          } else {
                              this.loadingPartner          =        true;
                              this.Partner();
                          }
                          
                     });
                     
    }
  },
  watch: {
    currentPage: {
      handler: function(value) {
        if (!isNullOrUndefined(value)) {
          this.fetchData().catch(() => {});
        }
      }
    }
  }
};
</script>
<style scope>
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
/* .multiselect__option--selected {
  font-size: 13px;
} */
.badge {
    padding: 6px !important;
    font-size: 0.8em !important;
    font-weight: 400 !important;
}
.table-set {
    width: 116px;
}
.slide-fade-leave-active {
  transition: all 0.1s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.badge-secondary {
    color: #fff  !important;
    background-color: #1487D6  !important;
    font-size: 10px !important;
    padding: 2px 12px  !important;
    margin-bottom: 8px  !important;
    margin-right: 4px  !important;
}
.multiselect {
    width: calc(50% - 32px) !important;
}
.custom__tag i {
    margin-left: 4px;
}
.multiselect__strong {  
    display: block !important;
    font-size: 11px !important;
}
.custom-control-label {
    width: auto !important;
    line-height: 1.75;
}
.multiselect__clear {
    position: absolute;
    right: 41px;
    height: 40px;
    width: 40px;
    display: block;
    cursor: pointer;
    z-index: 2;
}
.multiselect__clear::before {
    transform: rotate(45deg);
}
.multiselect__clear::after {
    transform: rotate(-45deg);
}
.multiselect__clear::after, .multiselect__clear::before {
    content: "";
    display: block;
    position: absolute;
    width: 3px;
    height: 16px;
    background: #aaa;
    top: 12px;
    right: 4px;
}
</style>
