<template>
    <div class="col-xs-12 col-sm-12 col-md-9">
        <div class="content">
            <header>
                <h2>Activity History</h2>
            </header>
            <transition name="slide-fade">
                <div>
                    <b-col sm="6" class="my-1">
                        <b-form-group>
                            <b-input-group size="sm">
                                <b-form-input
                                v-model="term"
                                v-on:keyup.enter="GetAllActivityHistory()"
                                type="search"
                                id="filterInput"
                                placeholder="Type to Search"
                                ></b-form-input>
                                <b-input-group-append>
                                <b-button v-b-tooltip.hover title="Search" @click="GetAllActivityHistory()">
                                    <i class="fas fa-search"></i>
                                </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <div class="col-sm-12">
                        <b-table :responsive="true" show-empty :fields="fields" :items="items" :current-page="currentPage" :busy="isBusy" :per-page="0">
                            <template v-slot:table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>

                            <template v-slot:cell(time)="data">
                                {{ ToTime(data.item.createdDate) }}
                            </template>

                            <template v-slot:cell(createdDate)="data">
                                {{ FormatTime(data.item.createdDate) }}
                            </template>
                        </b-table>
                        <b-pagination align="center" v-model="currentPage" :total-rows="totalItems" :per-page="perPage" size="sm" aria-controls="my-table"></b-pagination>
                    </div>
                    <div class="col-sm-12">
                        <header>
                            <h2>Download Activity History</h2>
                        </header>
                        <b-col sm="6">
                            <b-form-group>
                                <b-input-group size="sm">
                                    <b-form-input v-model="maxResult"  type="search" id="maxResult" placeholder="Number of records to download"></b-form-input>
                                    <b-input-group-append>
                                        <b-button v-b-tooltip.hover title="Download" @click="downloadActivityHistory()">
                                            <b-spinner small v-if="loading"></b-spinner>
                                            <span class="sr-only" v-if="loading">Loading...</span>
                                            <span v-if="!loading">
                                                <i class="fas fa-download mr-1"></i> Download
                                            </span>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import ActivityService from '../service/ActivityService'
import FunctionService from '../service/FunctionService';
export default {
    data() {
        return {
            currentPage:     1,
            perPage:         5,
            totalItems:      0,
            isBusy:          false,
            term:            "",
            fields:          [
                {
                    key:        "activityType",
                    label:      "Activity Type",
                    sortable:   true,
                    class:      "table-set"
                },
                {
                    key:        "module",
                    label:      "Module",
                    sortable:   true,
                    class:      "table-set"
                },
                {
                    key:        "from",
                    label:      "Old Value",
                    sortable:   true,
                    class:      "table-set"
                },
                {
                    key:        "to",
                    label:      "New Value",
                    sortable:   true,
                    class:      "table-set"
                },
                {
                    key:        "createdDate",
                    label:      "Date",
                    sortable:   true,
                    class:      "table-set"
                },
                {
                    key:        "time",
                    label:      "Time",
                    sortable:   true,
                    class:      "table-set"
                },
                {
                    key:        "username",
                    label:      "User",
                    sortable:   true,
                    class:      "table-set"
                },
                {
                    key:        "fullName",
                    label:      "FullName",
                    sortable:   true,
                    class:      "table-set"
                },
                {
                    key:        "ipAddress",
                    label:      "Ip Address",
                    sortable:   true,
                    class:      "table-set"
                }
            ],
            items:           [],
            maxResult : "",
            loading: false
        }
    },
    methods: {
        GetAllActivityHistory() {
            this.isBusy         =       true;
            
            ActivityService.GetActivityService(this.term,this.currentPage,this.perPage)
            .then((result) => {
                if (result.status == 201) {
                    this.totalItems     =    result.data.count;
                    this.items          =    result.data.data;
                    this.isBusy         =    false;
                    window.console.log(result);
                } else {
                    this.isBusy         =    false;
                    this.$notify({
                        group:   "notif",
                        type:    "success",
                        title:   "Info: ",
                        text:    "Failed Get Data Activity"
                    });
                }
            });
        },
        FormatTime(date) {
            return FunctionService.DateLong(date);
        },
        ToTime(time) {
            return FunctionService.Time(time);
        },
        JsonToData(data) {
            let toData      =       JSON.parse(data);
            // window.console.log(toData);
            return toData;
        },
        downloadActivityHistory(){
            // window.console.log("download");
            // window.console.log("max : " + this.maxResult);
            if(this.maxResult == "" || this.maxResult == 0){
                window.alert("Number of records cannot be null or 0");
            } else {
                this.loading = true;
                ActivityService.downloadActivityHistory("", 1, this.maxResult)
                .then((result) => {
                    if(result.status == 200){

                        let fileName = result.request.getResponseHeader('Content-Disposition');
                        
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName.replace("attachment; filename=", ""));
                        document.body.appendChild(link);
                        link.click();  

                        this.$notify({
                            group:   "notif",
                            type:    "success",
                            title:    "Download Complete",
                            duration:  5000
                        });
                    }
                }).catch(() => {
                    this.$notify({
                        group:   "notif",
                        type:    "info",
                        title:   "Download Failed",
                        duration:  5000
                    });
                }).finally(() => {
                    this.loading = false;
                })
            }
        }
    },
    watch: {
        currentPage: {
            handler: function() {
                this.GetAllActivityHistory();
            }
        }
    },
    mounted: function() {
        this.GetAllActivityHistory();
    }
}
</script>

<style lang="css" scoped>
    
</style>
