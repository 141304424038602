import { AXIOS } from '../helper/http-commons'
import header from '../helper/auth-header'

export default {
    GetAuthorization(offset, maxResult, term) {
        let config = {};
        config = header.authHeader();
        config.params = {
            offset: offset,
            maxResult: maxResult,
            term: term
        };
        return AXIOS.get('/authorization/', config);
    },
    Approved(id) {
        let config = {};
        config = header.authHeader();
        return AXIOS.put('/authorization/approved?id=' + id, "", config);
    },
    Reject(id) {
        let config = {};
        config = header.authHeader();
        return AXIOS.put('/authorization/rejected?id=' + id, "", config);
    }
}