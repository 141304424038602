<template>
    <div class="col-xs-12 col-sm-12 col-md-9">
        <div class="content">
            <header>
                <h2>Authorization</h2>
            </header>
            <div class="field-form">
                <b-col sm="6" class="my-1">
                    <b-form-group>
                        <b-input-group size="sm">
                            <b-form-input
                            type="search"
                            id="filterInput"
                            v-on:keyup.enter="SearchAuthorize()"
                            v-model="term"
                            placeholder="Type to Search"
                            ></b-form-input>
                            <b-input-group-append>
                                 <b-button v-b-tooltip.hover @click="SearchAuthorize()" title="Search">
                                    <i class="fas fa-search"></i>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </div>
            <transition name="slide-fade">
                <div class="table-result">
                    <b-table :fields="fields" :items="items" show-empty :busy="isBusy" :current-page="currentPage" :per-page="0" responsive>
                        <template v-slot:table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong> Loading...</strong>
                            </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <div class="btn-group">
                                <b-button class="approved" :disabled="disableApprove" v-if="data.item.status == 'PENDING'" @click="Approved(data.item)">
                                    <b-spinner small class="loading-button" :id="'approve'+data.item.id"></b-spinner>
                                    <div class="icon-select" :id="'approve'+data.item.id">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                </b-button>
                                <b-button class="reject" :disabled="disableReject" v-if="data.item.status == 'PENDING'" @click="Rejected(data.item)">
                                    <b-spinner small class="loading-button" :id="'reject'+data.item.id"></b-spinner>
                                    <div class="icon-select" :id="'reject'+data.item.id">
                                        <i class="far fa-times-circle"></i>
                                    </div>
                                </b-button>
                            </div>
                        </template>
                        <template v-slot:cell(view)="data" >
                           <div style="text-align: center;">
                               <div class="btn-group">
                                 <b-button @click="ViewAuthorize(data.item.authorizationData)"><i class="fas fa-eye"></i></b-button>
                               </div>
                           </div>
                        </template>
                        <template v-slot:cell(status)="data">
                            <div>
                                <b-badge v-if="data.item.status == 'PENDING'">{{ data.item.status }}</b-badge>
                                <b-badge v-if="data.item.status == 'APPROVED'" variant="success">{{ data.item.status }}</b-badge>
                                <b-badge v-if="data.item.status == 'REJECTED'" variant="danger">{{ data.item.status }}</b-badge>
                            </div>
                        </template>
                    </b-table>
                    <b-pagination align="center" v-model="currentPage" :total-rows="totalItems" :per-page="perPage" size="sm" aria-controls="my-table"></b-pagination>
                </div>
            </transition>
        </div>
        <div class="modal-info">
            <b-modal id="authorize" size="lg" hide-footer>
                <template v-slot:modal-title>
                    <div style="position: absolute;">
                        <h2>Data Authorize</h2>
                    </div>
                </template>
                <div>
                    <b-table :fields="fieldsView" :items="itemsView" responsive>
                        <template v-slot:cell(roles)="data">
                            <div style="text-align: center;">
                                <b-badge valriat="success" v-for="(item,index) in data.item.roles" :key="index">{{ item.rolename }}</b-badge>
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import authorization from '../service/authorization'
import FunctionService from '../service/FunctionService';

export default {
    data() {
        return {
            fields:                 [
                {
                    key:            "modul",
                    label:          "Modul"
                },
                {
                    key:            "action",
                    label:          "User Action"
                },
                {
                    key:            "createdBy",
                    label:          "Created By"
                },
                {
                    key:            "createdOn",
                    label:          "Created On"
                },
                {
                    key:            "status",
                    label:          "Status"
                },
                {
                    key:            "view",
                    label:          "View"
                },
                {
                    key:            "actions",
                    label:          "Actions"
                }
            ],
            items:                  [],
            fieldsView:             [],
            itemsView:              [],
            currentPage:            1,
            totalItems:             0,
            perPage:                5,
            term:                   "",
            isBusy:                 false,
            disableApprove:         false,
            disableReject:          false
        }
    },
    methods: {
        GetAuthorization(currentPage,perPage,term) {
            this.isBusy             =       true;
            this.items              =       [];
            authorization.GetAuthorization(currentPage,perPage,term)
                         .then((result) => {
                             if (FunctionService.ResultResponse(result)) {
                                 this.isBusy        =    false;
                                 window.console.log(result);
                                 for (let i = 0; i < result.data.data.length; i++) {
                                     this.items.push({
                                         authorizationData:         result.data.data[i].authorizationData,
                                         modul:                     result.data.data[i].modul,
                                         action:                    result.data.data[i].action,
                                         createdBy:                 result.data.data[i].createdBy,
                                         createdOn:                 FunctionService.FormatDateTime(result.data.data[i].createdOn),
                                         id:                        result.data.data[i].id,
                                         actions:                   result.data.data[i].id,
                                         status:                    result.data.data[i].status
                                     });
                                 }
                                 this.totalItems                =       result.data.count;
                             } else {
                                 this.isBusy        =    false;
                                 this.$notify({
                                     group:    "notif",
                                     title:    "Failed ",
                                     text:     result.message,
                                     type:     "error",
                                     duration: 5000
                                 });
                             }
                         })
                         .catch((err) => {
                             this.isBusy            =      false;
                             this.$notify({
                                group:    "notif",
                                title:    "Failed ",
                                text:     err,
                                type:     "error",
                                duration: 5000
                             });
                         });            
        },
        SearchAuthorize() {
            this.GetAuthorization(this.currentPage,this.perPage,this.term);
        },
        ViewAuthorize(item) {
            let JsonToData          =       JSON.parse(item);
            this.fieldsView         =       [];
            this.itemsView          =       [];

            let KeyView             =       [];
            Object.keys(JsonToData).forEach(function eachKey(key) {
                KeyView.push(key);
            });
            for (let i = 0; i < KeyView.length; i++) {
                this.fieldsView.push({
                    key:        KeyView[i]
                });
            }            
            this.itemsView          =       [JsonToData];
            this.$bvModal.show('authorize');
        },
        Approved(item) {
            let question        =       confirm("Are you Sure Approve "+ item.modul);
            if (question) {
                FunctionService.ShowLoading('approve'+item.id);
                this.disableApprove         =       true;
                authorization.Approved(item.id)
                             .then((result) => {
                                if (FunctionService.ResultResponse(result)) {
                                    window.console.log(result);
                                    this.$notify({
                                        group:   'notif',
                                        title:   'Authorized',
                                        text:    item.modul+ " "+ result.data.message,
                                        type:    "success",
                                        duration: 5000
                                    });
                                    this.disableApprove         =       false;
                                    this.GetAuthorization(this.currentPage,this.perPage,this.term);
                                    FunctionService.HideLoading('approve'+item.id);
                                } else {
                                    FunctionService.HideLoading('approve'+item.id);
                                    this.$notify({
                                        group:      'notif',
                                        title:      'Failed ',
                                        text:       result.message,
                                        duration:   5000
                                    });
                                    this.disableApprove         =       false;
                                }
                             })
                             .catch((err) => {
                                    FunctionService.HideLoading('approve'+item.id);
                                    if(err.response.status != 500){
                                        this.$notify({
                                            group:      'notif',
                                            title:      'Failed ',
                                            text:       err.response.data.message,
                                            duration:   5000
                                        });
                                    } else {
                                        this.$notify({
                                            group:      'notif',
                                            title:      'Error ',
                                            text:       err,
                                            duration:   5000
                                        });
                                    }
                                    this.disableApprove         =       false;
                             });
            }
        },
        Rejected(item) {
            let question        =         confirm("Are you Sure Reject "+item.modul);
            if (question) {
                FunctionService.ShowLoading('reject'+item.id);
                this.disableReject           =      true;
                authorization.Reject(item.id)
                             .then((result) => {
                                 if (FunctionService.ResultResponse(result)) {
                                     FunctionService.HideLoading('reject'+item.id);
                                     this.$notify({
                                        group:   'notif',
                                        title:   'Rejeected',
                                        text:    item.modul+ " "+ result.data.message,
                                        type:    "success",
                                        duration: 5000
                                     });
                                     this.GetAuthorization(this.currentPage,this.perPage,this.term);
                                     this.disableReject           =      false;
                                 } else {
                                     this.$notify({
                                         group:      'notif',
                                         title:      'Failed ',
                                         text:       result.data.message,
                                         duration:   5000,
                                         type:       'error'
                                     });
                                     FunctionService.HideLoading('reject'+item.id);
                                     this.disableReject           =      false;
                                 }
                             })
                             .catch((err) => {
                                    FunctionService.HideLoading('reject'+item.id);
                                    if(err.response.status != 500){
                                        this.$notify({
                                            group:      'notif',
                                            title:      'Failed ',
                                            text:       err.response.data.message,
                                            duration:   5000
                                        });
                                    } else {
                                        this.$notify({
                                            group:      'notif',
                                            title:      'Error ',
                                            text:       err,
                                            duration:   5000
                                        });
                                    }
                                    this.disableApprove         =       false;
                             });
            }
        }
    },
    watch: {
        currentPage: {
            handler: function() {
                this.GetAuthorization(this.currentPage,this.perPage,this.term);
            }
        }
    },
    mounted: function() {
        this.GetAuthorization(this.currentPage,this.perPage,this.term);
    }
}
</script>

<style lang="scss">
    
</style>
