<template>
  <div class="col-xs-12 col-sm-12 col-md-9">
      <div class="content">
          <header>
              <h2>Service Log</h2>
          </header>
          <transition name="slide-fade">
              <div>
                  <div class="form-data">
                      <div class="row">
                          <div class="col-md-4">
                              <div class="form-group">
                                  <input type="date" v-model="date">
                              </div>
                          </div>
                          <div class="col-md-4">
                              <div class="form-group">
                                  <div class="download-btn">
                                      <button class="button" @click="downloadLog()">Download Log</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-12">
                              <div class="alert alert-warning">
                                  The logs available for download are from yesterday's date, because today's log creation will be done at 00.00 midnight
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </transition>
      </div>
      
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import ServiceLog from '../service/ServiceLog';
import swal from 'sweetalert';
import $ from "jquery";

export default {
  data() {
      return {
          date: "",
      }
  },
  mounted: function() {
      var dtToday = new Date();
  
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate() - 1;
      var year = dtToday.getFullYear();
      if(month < 10) month = '0' + month.toString();
      if(day < 10) day = '0' + day.toString();
      var maxDate = year + '-' + month + '-' + day;
      
      $('input[type="date"]').attr('max', maxDate);
  },
  methods: {
      downloadLog() {
          if(this.date === '' || this.date === undefined){
              this.$notify({
                  group:  "notif",
                  title:  "Failed download",
                  text:   'Please select the date',
                  duraition: 5000,
                  type:   "error"
              });
              return;
          }
          ServiceLog.downloadLog(this.date)
          .then((result) => {
              if(result.data.byteLength > 100){
                  let blob = new Blob([result.data], { type: 'application/zip' })
                  let link = document.createElement('a')
                  link.href = window.URL.createObjectURL(blob)
                  link.download = this.date + '.zip'
                  link.click()
                  this.$notify({
                      group:  "notif",
                      title:  "Logs Download",
                      text:   'log for the '+ this.date +' successfully downloaded',
                      duraition: 5000,
                      type:   "success"
                  });
              }else{
                  swal({
                      title: "Sorry. The file you are looking for does not exist.", 
                      icon: "warning",
                  })
              }
          })
          .catch((error) => {
              window.console.log('error',error.response)
          })
          
      },
  },
}
</script>

<style lang="css" scoped>
  .form-data {
      position: relative;
      margin-left: 5px;
  }
  .download-btn button {
      color: white;
      background: #6c757d;
      height: 33px;
      margin-top: 10px;
  }
  input:focus ~ .placeholder, .input:valid ~ .placeholder {
      display: none;
  }
  .form-data .search-partner .placeholder {
      position: absolute;
      top: 8px;
      left: 33px;
      font-size: 0.9em;
      z-index: 0;
      color: gray;
      cursor: pointer;
  }
  .form-data .search-partner .placeholder:hover {
      display: none;
  }
  .form-group input {
      width: 100%;
  }
  .fieldset .file-form button {
      color: white;
      background-color: #6c757d;
      width: 32%;
      position: absolute;
      bottom: 64px;
      height: 36px;
      width: 46px;
  }

  @media only screen and (max-width: 992px) {
      .fieldset .file-form button {
          top: 27px;
      }
  }
</style>