import { AXIOS } from "../helper/http-commons"
import header from "../helper/auth-header"
export default {
    downloadLog(date) {
        return AXIOS({
            method:         'get',
            url:            '/service-log/download/'+date,
            responseType:   'arraybuffer',
            headers:        {
                "Authorization":  header.authHeader().headers.Authorization,
                "Content-Type":   'application/json'
            }
        });
    },
}