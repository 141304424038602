import { AXIOS } from "../helper/http-commons"
import header from "../helper/auth-header"
export default {
    UploadT24(data) {
        return AXIOS.post('/reconcile/upload/core-data',data,header.HeaderUpload());
    },
    UploadiGate(data) {
        return AXIOS.post('/reconcile/upload/igate-data',data,header.HeaderUpload());
    },
    ReconProsess(date,partner) {
        if (partner == undefined) {
            return AXIOS.post('/reconcile/process-data?date='+date,"", header.authHeader());
        } else {
            return AXIOS.post('/reconcile/process-data?date='+date+'&partnerId='+partner,"", header.authHeader());
        }
        
    },
    ListDoneProcessRecon(offset,maxResult,term) {
        return AXIOS.get('/reconcile/?offset='+offset+"&maxResult="+maxResult+"&term="+term, header.authHeader());
    },
    
    DownloadFile(id) {
        return AXIOS({
            method:         'get',
            url:            '/reconcile/download/'+id,
            responseType:   'arraybuffer',
            headers:        {
                "Authorization":  header.authHeader().headers.Authorization,
                "Content-Type":   'application/json'
            }
        });
    },
    SendEmail(email,id) {
        return AXIOS.post('/reconcile/send?email='+email+"&documentId="+id,"",header.authHeader());
    }
}
