<template>
  <body>
    <div class="container" style="padding: 0">
      <nav>
        <div class="nav-toggle">
          <i class="fas fa-bars"></i>
        </div>
        <div class="nav-brand">
          <!-- <span v-if="typeof data.userInfo !== 'undefined'">Welcome, {{data.userInfo.userName}}</span> -->
          <div class="logo"></div>
        </div>
        <div class="nav-account">
          <div class="info">
          <span v-if="CheckUsers(data.userInfo)">{{ timenow }} - {{ userName }}</span>
          </div>
          <div class="logout" v-on:click="callLogout()">
            <div class="circle">
              <i class="fas fa-power-off"></i>
            </div>
            <span>Logout</span>
          </div>
        </div>
      </nav>
    </div>
    <main>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <div class="sidebar" id="accordion">
              <div class="menu" v-for="(item, idx) in data.uiAccess" :key="idx">
                <div class="title">
                  <i :class="item.iconStyle"></i>
                  <p v-if="!item.childEl.length">
                    <router-link :to="item.page" tag="li" exact>{{
                      item.uiName
                    }}</router-link>
                  </p>
                  <p v-if="item.childEl.length" @click="toggle">
                    {{ item.uiName }}
                  </p>
                </div>
                <ul class="dropdown">
                  <li v-for="(child, i) in item.childEl" :key="i">
                    <router-link :to="child.page" exact>
                      <div class="row">
                        <div class="col-md-1">
                          <i :class="child.iconStyle"></i>
                        </div>
                        <div class="col-md-11">
                          <p>{{ child.uiName }}</p>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <transition name="moveInUp">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </main>
    <footer>
      <p>
        <a href=""
          >Copyright © Bank RBI {{ new Date().getFullYear() }}</a
        >
      </p>
    </footer>
  </body>
</template>

<script>
import "select2/dist/js/select2";
// import "jquery-ui/ui/jquery-1-7";
import "jquery-ui";
import "slick-carousel/slick/slick";
import $ from "jquery";
import { isNullOrUndefined } from "util";
import * as moment from "moment";
// import { setInterval } from 'timer
import swal from 'sweetalert';
import FunctionService from '../service/FunctionService';

export default {
  name: "home",
  data() {
    return {
      data: {},
      userName: "",
      loginError: false,
      error: false,
      errors: [],
      isActive: false,
      timenow: "",
      SessionTimeOut: [],
      Version: "",
    };
  },
  components: {
    // 'fa-icons': Faicon,
  },
  computed: {
    dataComputed() {
      return this.data;
    },
  },
  methods: {
    toggle() {
      $(".menu .title").click(function (e) {
        var current_dropdown = $(this).next(".dropdown");
        $(".dropdown").not(current_dropdown).slideUp();
        current_dropdown.slideDown();

        e.stopPropagation();
      });

      if ($(".dropdown li").hasClass("active")) {
        $(".active").parent(".dropdown").toggle();
        $(".active")
          .parent(".dropdown")
          .siblings(".title")
          .css("border-left", "4px solid #1487D6");
        $(".active")
          .parent(".dropdown")
          .siblings(".title")
          .find("i, p")
          .css("color", "#1487D6");
      }

      if ($(".menu .title p").hasClass("active")) {
        $(".active").parent(".title").css("border-left", "4px solid #1487D6");
        $(".active").css("color", "#1487D6");
        $(".active").siblings("i").css("color", "#1487D6");
      }
    },
    callLogout() {
      // this.SessionTime('stop');
      FunctionService.SaveSessionCustom('sessionIdle', false);
      this.$store.dispatch("logout");
    },
    sidebar() {
      // initial JS nav toggle menu
      let MediaScreen = window.matchMedia("(max-width: 992px)");
      $("nav .nav-toggle").on("click", function () {
        $(".sidebar").toggle();
      });
      if (MediaScreen.matches == true) {
        $(".sidebar").css("display", "none");
        $(".sidebar").on("click", function () {
          $(".sidebar").css("display", "none");
        });

        $("nav .nav-toggle").on("click", function () {
          $(".sidebar").toggle();
        });
      } else {
        $(".sidebar").on("click", function () {
          $(".sidebar").css("display", "block");
        });
        $(".sidebar").css("display", "block");
      }

      MediaScreen.addListener(this.sidebar);
    },
    GetProfile() {
      this.$store
        .dispatch("getProfile")
        .then((response) => {
          window.console.log(response);
          this.data = response.data;
          this.userName   =   response.data.userInfo.userName;
          window.console.log(response.data);
          this.Version = response.data.version;
          if (response.data.uiAccess == null) {
            this.callLogout();
          }
          //response.data.sessionTimeOut
          this.SessionTime(response.data.sessionTimeOut);
          if (!isNullOrUndefined(this.data.uiAccess)) {
            this.data.uiAccess.sort(function (a, b) {
              return a.sortOrder - b.sortOrder;
            });
          }
        })
        .catch((error) => {
          // window.console.log(error);
          this.$router.push("/Login");
          this.loginError = true;
          this.errors.push(error);
          this.error = true;
        });
    },
    CheckUsers(user) {
      return typeof user !== undefined;
    },
    SessionTime(time) {
      let idleTime = time;
      let stop;
      FunctionService.SaveSessionCustom('sessionIdle', true);
      $(document).ready(function () {
        stop = setInterval(timerIncrement, 1000);
        

        $(this).mousemove(function () {
          idleTime = time;
        });

        $(this).keypress(function () {
          idleTime = time;
        });
      });
      
      let Store       = this.$store;
      // let router      = this.$router;
      let sessionTime = this.SessionTime;
      
      function timerIncrement() {
        idleTime = idleTime - 1;
        let Status   =  FunctionService.ReadSessionCustom('sessionIdle');
        if (!Status) {
          clearInterval(stop);
        }
        // window.console.log(Status);
        // window.console.log(idleTime);
        if (idleTime <= 0) {
          clearInterval(stop);
          swal({
            title: "session is over whether to continue the session",
            icon: "warning",
            buttons: true,
            closeOnClickOutside: false
          })
          .then((question) => {
            if (question) {
              // idleTime = time;
              sessionTime(time);
            } else {
              clearInterval(stop);
              Store.dispatch("logout");
            }
          });
        }
      }
    },
  },
  mounted: function () {
    this.sidebar();
    // this.SessionTime();
  },
  created() {
    window.addEventListener("beforeunload", function () {
      var localStorageTime = localStorage.getItem("storagetime");
      if (localStorageTime != null && localStorageTime != undefined) {
        var currentTime = new Date().getTime(),
          timeDifference = currentTime - localStorageTime;

        if (timeDifference < 25) {
          //Browser Closed
          localStorage.removeItem("storagetime");
          this.callLogout();
        } else {
          //Browser Tab Closed
          localStorage.setItem("storagetime", new Date().getTime());
          this.callLogout();
        }
      } else {
        localStorage.setItem("storagetime", new Date().getTime());
      }
    });
    this.errors = [];
    setInterval(() => {
      let time = moment().format("dddd, D MMMM YYYY - h:mm:ss A");
      this.timenow = time;
    }, 1000);
    this.GetProfile();
  },
};
</script>

<style scoped>
@import "../assets/css/sanitize.css";
@import "../assets/css/all.css";
@import "../assets/scss/layout/_sidebar.scss";

body {
  background: #f5f5f5;
  padding-bottom: 50px;
  position: relative;
  margin: 0;
  min-height: 100vh;
}
.moveInUp-enter-active {
  animation: fadeIn 0.1s ease-in;
}
.nav-account .logout .circle {
  background-color: #03ad09 !important;
}
.logo {
  position: relative;
  margin-left: 27px;
  width: 40px;
  border-radius: 110px;
  /* border: 1px solid; */
  overflow: hidden;
}
.nav-brand h3 {
  position: relative;
  color: white;
  text-transform: uppercase;
  margin-left: 4px;
  font-size: 0.9em;
}
.logo img {
  width: 50%;
  height: 50% !important;
}
.dropdown li a p {
  position: relative;
  display: inline;
  top: 0px;
  margin-top: 6px;
  margin-left: 6px;
}
.dropdown li a i {
  margin-left: 0px;
  margin-right: 0px;
}
nav .nav-account .logout span {
  color: #cd0a0a !important;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.moveInUp-leave-active {
  animation: moveInUp 0.1s ease-in;
}
@keyframes moveInUp {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-400px);
  }
}

@media only screen and (max-width: 992px) {
  nav {
    position: relative !important;
  }
}
@media screen and (max-width: 799px) {
  .dropdown .row .col-md-1 {
    width: 12px;
  }
  .dropdown .row .col-md-11 {
    width: 198px;
  }
}
</style>