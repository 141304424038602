<template>
    <div class="col-xs-12 col-sm-12 col-md-9">
        <div class="content">
            <header>
                <h2>Reconciliation</h2>
            </header>
            <transition name="slide-fade">
                <div>
                    <div class="form-data">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="search-partner">
                                        <input type="text" placeholder="Search By Partner ID" v-model="partnerId">
                                        <!-- <span class="placeholder">Search By Partner ID</span>
                                        <i class="fa fa-search placeholder" aria-hidden="true"></i> -->
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="download-btn">
                                        <button class="button" @click="Search()">Search</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="date" v-model="dateRecon">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table result">
                        <b-table :fields="fields" show-empty :items="items" :per-page="perPage" :busy="isBusy">
                            <template v-slot:cell(actions)="data">
                                <div>
                                    <b-button v-b-tooltip.hover title="Download" @click="Download(data.item.id)">
                                        <i class="fas fa-download"></i>
                                    </b-button> 
                                    <b-button v-b-tooltip.hover title="Send email" @click="ModalShow(data.item.id)" style="margin-left: 12px;">
                                        <i class="fas fa-envelope"></i>
                                    </b-button> 
                                </div>
                            </template>
                            <template v-slot:cell(createdOn)="data">
                                <div>
                                    {{ FormatDate(data.item.createdOn) }}
                                </div>
                            </template>
                            <template v-slot:cell(createdTime)="data">
                                {{ FormatTime(data.item.createdTime) }}
                            </template>
                            <template v-slot:table-busy>
                                <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination align="center" :total-rows="totalItems" v-model="currentPage" :per-page="perPage" size="sm" aria-controls="my-table"></b-pagination>
                    </div>
                </div>
            </transition>
        </div>
        <div class="send-email">
            <b-modal id="email" hide-footer v-model="modalShow">
                <template v-slot:modal-title>
                    <h1>Send to email</h1>
                </template>
                <div class="body-modal">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control email" placeholder="your email" v-model="email">
                    </div>
                    <div class="form-group">
                        <b-button @click="SendEmail()" :disabled="loading">
                            <b-spinner small v-if="loading"></b-spinner>
                            <i v-if="!loading" class="fa fa-paper-plane" aria-hidden="true"></i>
                        </b-button> 
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import ReconciliationService from '../service/ReconciliationService';
import FunctionService from '../service/FunctionService';
export default {
    data() {
        return {
            fields:         [
{
                    key:        "fileName",
                    label:      "Filename",
                    sortable:   true
                },
                {
                    key:        "createdOn",
                    label:      "Created Date",
                    sortable:   true
                },
                {
                    key:        "createdBy",
                    label:      "Created",
                    sortable:   true
                },
                {
                    key:        "createdTime",
                    label:      "Time"
                },
                {
                    key:        "actions",
                    label:      "Actions",
                }
            ],
            items:                  [],
            currentPage:            1,
            totalItems:             0,
            perPage:                5,
            isBusy:                 false,
            term:                   "",
            partnerId:              "",
            dateRecon:              "",
            modalShow:              false,
            id:                     "",
            email:                  "",
            loading:                false
        }
    },
    methods: {
        ModalShow(id) {
            this.modalShow      =       true;
            this.id             =       id;
        },
        SendEmail() {
            if (this.email == '') {
                return FunctionService.notifyForm('email','Email Required','info');
            } else if (FunctionService.ValidationEmail(this.email) == false) {
                return FunctionService.notifyForm('email','email required @','info');
            } else {
                this.loading        =       true;
                ReconciliationService.SendEmail(this.email,this.id)
                .then((result) => {
                    if (result.status == 201) {
                        this.loading        =       false;
                        this.$notify({
                            group:      "notif",
                            title:      "Send email Successed",
                            text:       result.data.message,
                            type:       "success",
                            duration:   5000
                        });
                        this.modalShow      =       false;
                    } else {
                        this.loading        =       false;
                        this.$notify({
                            group:      "notif",
                            title:      "Failed Send email",
                            text:       result.data.message,
                            type:       "error",
                            duration:   5000
                        });
                    }
                });
            }
            
        },
        GetRecon() {
            this.isBusy         =        true;
            ReconciliationService.ListDoneProcessRecon(this.currentPage,this.perPage,this.term)
            .then((result) => {
                if (result.status == 201) {
                    this.items      =       result.data.data;
                    this.isBusy     =       false;
                    this.totalItems =       result.data.count;
                } else {
                    this.$notify({
                        group:  "notif",
                        title:  "Failed get Recon",
                        text:   result.data.message,
                        duraition: 5000,
                        type:   "error"
                    });
                    this.isBusy         =       false;
                }
                window.console.log(result);
            });
        },
        FormatDate(date) {
            return FunctionService.DateLong(date);
        },
        FormatTime(time) {
            return FunctionService.Time(time);
        },
        Download(id) {
            let question         =    confirm("are you want download file ?");
            if (question == true) {
                ReconciliationService.DownloadFile(id)
                .then((result) => {
                    let blob = new Blob([result.data], { type: 'application/vnd.ms-excel' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Report.xls'
                    link.click()
                    window.console.log(result); 
                });
                window.console.log(id);
            }
        },
        Search() {
            this.term       =       this.dateRecon+" "+this.partnerId;
            this.GetRecon();
        }
    },
    watch: {
        currentPage: {
            handler: function() {
                this.GetRecon();
            }
        }
    },
    mounted: function() {
        this.GetRecon();
    }
}
</script>

<style lang="css" scoped>
    .form-data {
        position: relative;
        margin-left: 5px;
        padding-top: 26px;
    }
    .download-btn button {
        color: white;
        background: #6c757d;
        height: 33px;
    }
    .form-data .search-partner input {
        /* border-radius: 52px; */
        width: 100%;
    }
    .form-data .search-partner i {
        position: absolute;
        top: 11px;
        left: 12px;
        color: grey;
    }
    .form-data .search-partner {
        position: relative;
    }
    input:focus ~ .placeholder, .input:valid ~ .placeholder {
        display: none;
    }
    .form-data .search-partner .placeholder {
        position: absolute;
        top: 8px;
        left: 33px;
        font-size: 0.9em;
        z-index: 0;
        color: gray;
        cursor: pointer;
    }
    .form-data .search-partner .placeholder:hover {
        display: none;
    }
    .form-data .search-partner .fa-search {
        left: 14px;
        top: 12px;
    }
    .form-group input {
        width: 100%;
    }
    .fieldset .file-form button {
        color: white;
        background-color: #6c757d;
        width: 32%;
        position: absolute;
        bottom: 64px;
        height: 36px;
        width: 46px;
    }

    @media only screen and (max-width: 992px) {
        .fieldset .file-form button {
            top: 27px;
        }
    }
</style>
