import moment from 'moment'
import $ from "jquery";
import Papa from 'papaparse'
import 'notifyjs-node/dist/notify'
import Crypto from "../tools/crypto"



export default {
    DateLong(datetime) {
        try {
            let newDate = new Date(datetime);
            let momentFormat = moment(String(newDate)).format('MM-DD-YYYY');
            return momentFormat;
        } catch (e) {
            window.console.log(e);
            return e;
        }
    },
    SimpleDateFormat(date) {
        var datetime = moment(date, "YYMMDDHHmm").format('YYYY-MM-DD HH:mm');
        return datetime;
    },
    ReadSaveSelectedDataCustom(key) {
        try {
            let readData = localStorage.getItem(key);
            let JSONtoData = JSON.parse(readData);
            return JSONtoData;
        } catch (error) {
            window.console.log(error);
            return error;
        }
    },
    FormatDate(datetime) {
        try {
            let momentFormat = moment(datetime).format('YYYY-MM-DD');
            return momentFormat;
        } catch (e) {
            window.console.log(e);
            return e;
        }
    },
    FormatDateTime(datetime) {
        try {
            let momentFormat = moment(datetime).format('YYYY-MM-DD h:mm A');
            return momentFormat;
        } catch (e) {
            window.console.log(e);
            return e;
        }
    },
    Time(datetime) {
        try {
            let newDate = new Date(datetime);
            let momentFormat = moment(String(newDate)).format('LT');
            return momentFormat;
        } catch (e) {
            window.console.log(e);
            return e;
        }
    },
    GetDateNow() {
        try {
            let dateNow = new Date();
            let momentTime = moment(String(dateNow)).format('YYYY-MM-DD');
            return momentTime;
        } catch (e) {
            window.console.log(e);
            return e;
        }
    },
    ValidationEmail(email) {
        try {
            let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var result = regex.test(String(email).toLocaleLowerCase());
            return result;
        } catch (error) {
            window.console.log(error);
            return error;
        }
    },
    MinuteToSecond(minute) {
        try {
            return Math.floor(minute * 60);
        } catch (e) {
            window.console.log(e);
            return e;
        }
    },
    HideLoading(id) {
        $('button #' + id).css('display', 'none');
        $('#' + id + '.icon-select').css('display', 'block');
    },
    ShowLoading(id) {
        $('button #' + id).css('display', 'block');
        $('#' + id + '.icon-select').css('display', 'none');
    },
    CheckExtention(file) {
        if (file == null || undefined) {
            return alert("No file exist!");
        } else {
            const lastPoint = file.name.lastIndexOf('.');
            const ext = file.name.substring(lastPoint + 1);
            if (ext == "csv") {
                return true;
            } else {
                return false;
            }
        }

    },
    ReadCsv(file) {
        return new Promise((resolve) => {
            Papa.parse(file, {
                complete: function(result) {
                    resolve(result);
                }
            });
        });
    },
    notifyForm(element, message, type) {
        $("." + element).notify(message, type);
    },
    TableWidth() {
        window.console.log('width');
        $('.table thead th').css('width', '140');
    },
    CheckToJSON(data) {
        try {
            return JSON.parse(data);
        } catch (e) {
            return false;
        }
    },
    isObject(val) {
        if (val.length > 1 || val == true || val == false) {
            return true;
        } else {
            return false;
        }
    },
    ResultResponse(response) {
        if (response.status == 201) {
            return true;
        } else {
            return false;
        }
    },
    SaveSessionCustom(key, value) {
        let ToJson = JSON.stringify(value);
        let encrypt = Crypto.encryption(ToJson);
        return localStorage.setItem(key, encrypt);
    },
    ReadSessionCustom(key) {
        let read = localStorage.getItem(key);
        // // window.console.log(read);
        if (read == 'null') {
            return null;
        } else {
            let dencrypt = Crypto.dencrytion(read);
            let JsonToData = JSON.parse(dencrypt);
            return JsonToData;
        }
    },

}