<template>
    <div class="col-xs-12 col-sm-12 col-md-9">
        <div class="content">
            <header>
                <h2>Reconciliation Process</h2>
            </header>
            <transition name="slide-fade">
                <div>
                    <div class="form-data">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="search-partner">
                                        <input type="date" v-model="SelectDate" class="SelectDate">
                                        <!-- <span class="placeholder">Search By Partner ID</span>
                                        <i class="fa fa-search placeholder" aria-hidden="true"></i> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="fieldset">
                                        <!-- <div class="file-form">
                                            <input type="text" placeholder="Partner ID" v-model="PartnerID" class="PartnerID">
                                            <b-button v-b-tooltip.hover title="Process" style="height: 37px;" :disabled="loading" @click="Process()">
                                                <b-spinner small v-if="loading"></b-spinner>
                                                <i class="fa fa-refresh" aria-hidden="true" v-if="!loading"></i>
                                            </b-button>
                                        </div> -->
                                        <multiselect style="width: 100% !important;white-space: nowrap;"  placeholder="Partner ID" deselect-label="Can't remove this value" track-by="partnerName" v-model="PartnerID" label="partnerName" :loading="loadingPartner" :options="DataPartner" :searchable="true" :allow-empty="false" @search-change="Partner" >
                                            <template slot="singleLabel" slot-scope="{ option }">{{ option.partnerName }}</template>
                                        </multiselect>
                                        <b-button v-b-tooltip.hover title="Process" style="height: 37px;" :disabled="loading" @click="Process()">
                                            <b-spinner small v-if="loading"></b-spinner>
                                            <i class="fa fa-refresh" aria-hidden="true" v-if="!loading"></i>
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table result">
                        <!-- <b-table :fields="fields" :items="items" show-empty :per-page="perPage">
                            <template v-slot:cell(actions)="">
                                <div>
                                    <a  href="#">Process</a>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination align="center" :total-rows="totalItems" :per-page="perPage" size="sm" aria-controls="my-table"></b-pagination> -->
                        <b-alert :show="dismissCountDown" dismissible variant="success" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                            <p>Reconcilliation process on {{ SelectDate }} with partner name {{ PartnerID.partnerName }} will be completed on {{ dismissCountDown }} </p>
                            <b-progress
                                variant="success"
                                :max="dismissSecs"
                                :value="dismissCountDown"
                                height="4px"
                            ></b-progress>
                        </b-alert>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import FunctionService from '../service/FunctionService';
import ReconciliationService from '../service/ReconciliationService';
import Multiselect from "vue-multiselect";
// import parameterService from '../service/parameter-service';
import partnerService from '../service/partner-service';

export default {
    data() {
        return {
            fields:         [
                {
                    key:        "filename",
                    label:      "T24 Filename",
                    sortable:   true
                },
                {
                    key:        "Size",
                    label:      "Size",
                    sortable:   true
                },
                {
                    key:        "igatename",
                    label:      "Igate Filename",
                    sortable:   true
                },
                {
                    key:        "sizes",
                    label:      "Size",
                    sortable:   true
                },
                {
                    key:        "date",
                    label:      "Created Date",
                    sortable:   true
                },
                {
                    key:        "actions",
                    label:      "Actions",
                }
            ],
            items:                  [],
            currentPage:            1,
            loading:                false,
            totalItems:             0,
            perPage:                5,
            PartnerID:              [],
            SelectDate:             '',
            dismissSecs:            10,
            dismissCountDown:       0,
            showDismissibleAlert:   false,
            DataPartner:            [],
            loadingPartner:         false,
            TermPartner:            ""
        }
    },
    components: {
        Multiselect
    },
    methods: {
        Process() {
            if(this.SelectDate == '') {
                return FunctionService.notifyForm('SelectDate','plesse Select date recon','info');
            } else {
                this.loading            =       true;
                ReconciliationService.ReconProsess(this.SelectDate,this.PartnerID.id)
                .then((result) => {
                    if (result.data.code == "00") {
                        this.loading    =       false;
                        this.showAlert(); 
                    } else {
                        this.$notify({
                            group: "notif",
                            title: "Failed Process Recon",
                            text:  result.data.message,
                            duration: 5000,
                            type:  "error"
                        });
                    }
                    // window.console.log(result);
                });
            }
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        Partner() {
            this.loadingPartner          =        true;
            partnerService.getPartner(this.TermPartner)
                            .then((result) => {
                                if (result.status == 201) {
                                    this.loadingPartner          =        false;
                                    this.DataPartner             =      result.data.data;
                                } else {
                                    this.loadingPartner          =        true;
                                    this.Partner();
                                }
                                window.console.log(result);
                            });
        }
    },
    mounted: function() {
        this.Partner();
        this.totalItems         =       this.items.length;
    }
}
</script>

<style lang="css" scoped>
    .form-data {
        position: relative;
        margin-left: 5px;
        padding-top: 26px;
    }
    
    .form-data .search-partner input {
        width: 100%;
    }
    .form-data .search-partner i {
        position: absolute;
        top: 11px;
        left: 12px;
        color: grey;
    }
    .form-data .search-partner {
        position: relative;
    }
    input:focus ~ .placeholder, .input:valid ~ .placeholder {
        display: none;
    }
    .form-data .search-partner .placeholder {
        position: absolute;
        top: 8px;
        left: 33px;
        font-size: 0.9em;
        z-index: 0;
        color: gray;
        cursor: pointer;
    }
    .form-data .search-partner .placeholder:hover {
        display: none;
    }
    .form-data .search-partner .fa-search {
        left: 14px;
        top: 12px;
    }
    .form-group input {
        width: 100%;
    }
    .fieldset .file-form button {
        color: white;
        background-color: #6c757d;
        width: 32%;
        position: absolute;
        bottom: 24px;
        height: 36px;
        width: 66px;
    }

    @media only screen and (max-width: 992px) {
        .fieldset .file-form button {
            top: 27px;
        }
    }
</style>
