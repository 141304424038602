<template>
        <div class="col-xs-12 col-sm-12 col-md-9">
          <div class="content" style="text-align:center;">
              <p><strong>Welcome to web-admin open api router.</strong></p>
          </div>
        </div>
</template>

<script>

  export default {
    name: 'dashboard',

    data () {
      return {
        response: [],
        errors: []
      }
    },
    methods: {
    
    }
  }

</script>